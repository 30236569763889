// this is used by bootswatch to import the font, we bundle all fonts so there
// is nothing to import
// https://github.com/thomaspark/bootswatch/issues/55
$web-font-path: "data:text/css;base64,";

// Default for Bootstrap is 700 and it's a bit too thick for Open Sans
$font-weight-bold: 600;

// this is vaniall flatly but Lato was replaced by Open Sans
// the rest was kept for fallback used by "font-display: swap;"
$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
