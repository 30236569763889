.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text;
}

.mw-1p {
  min-width: 1%;
}
