@import "Fonts";

$font-size-base: 1rem;
$body-bg: #455a64;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/reboot";
